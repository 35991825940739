<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                   <b-col class="col-lg-6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('procurement.lc_no')"
                    label-for="lc_no"
                    >
                    <b-form-input
                        id="registration_no"
                        v-model="search.lc_no"
                        placeholder=""
                        ></b-form-input>
                    </b-form-group>
                   </b-col>
                    <b-col class="col-sm-2">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('movement.portWiseAllocationManagement')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" variant="primary" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(po_memo_no)="data">
                                          {{ $n(data.item.po_memo_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(total_allocated_amount)="data">
                                          {{ $n(data.item.total_allocated_amount, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(lc_no)="data">
                                          {{ $n(data.item.lc_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(allocation_date)="data">
                                          {{ data.item.allocation_date | dateFormat }}
                                        </template>
                                         <template v-slot:cell(lc_date)="data">
                                           {{ $n(parseInt(data.item.lc_date.split('-')[0])) + '-' +  $n(data.item.lc_date.split('-')[1]) + '-' + $n(data.item.lc_date.split('-')[2]) }}
                                           </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-5 @click="edit(data.item)" title="Port Allocation Details"><i class="fas fa-eye"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" v-b-modal.modal-4 @click="edit(data.item)" title="Edit"  v-if="data.item.status == 1"><i class="fas fa-pen"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" @click="remove(data.item)" v-if="data.item.status == 1"><i class="fas fa-times"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_status" @click="finalSave(data.item)" v-if="data.item.status == 1"><i class="fas fa-check"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="xl" :title="$t('movement.portAllocationDetails')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title class="flex-grow-1">
            {{ $t('movement.portAllocationDetails') }}
            <b-button variant="light" @click="pdfExport" class="mr-2 float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
          <Details :id="editItemId" :key="editItemId" ref="details"/>
        </b-modal>
        <!-- <pre>{{$store.state.SeedsFertilizer.commonObj.monthList}}</pre> -->
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { portAllocationList, portAllocationFinalSave, lcList, portAllocationDestroy } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form,
        Details
    },
    data () {
        return {
            search: {
                id: 0,
                lc_no: ''
            },
            lcList: []
        }
    },
    computed: {
        cnfAgentList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.cnfAgentList
        },
        fertilizerNameList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
        },
        importCountryList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('movement.portAllocationEntry') : this.$t('movement.portAllocationModify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('procurement.lc_no'), class: 'text-center' },
                { label: this.$t('movement.vessels_name'), class: 'text-center' },
                { label: this.$t('movement.fertilizerName'), class: 'text-center' },
                { label: this.$t('movement.sourceCountry'), class: 'text-center' },
                { label: this.$t('movement.memoNo'), class: 'text-center' },
                { label: this.$t('movement.allocationDate'), class: 'text-center' },
                { label: this.$t('movement.totalAllocatedAmount'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'lc_no' },
                { key: 'ship_name_bn' },
                { key: 'fertilizer_name_bn' },
                { key: 'country_name_bn' },
                { key: 'memo_no' },
                { key: 'allocation_date' },
                { key: 'total_allocated_amount' },
                { key: 'action' },
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'lc_no' },
                { key: 'ship_name' },
                { key: 'fertilizer_name' },
                { key: 'country_name' },
                { key: 'memo_no' },
                { key: 'allocation_date' },
                { key: 'total_allocated_amount' },
                { key: 'action' },
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.getLcList()
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        changeStatusCustom (baseUrl, uri, item) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
            if (response.success) {
              /** The following line commented as list is reloaded */
              // Store.dispatch('toggleStatus', item)
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        toggleStatusCustom (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.deleteConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        toggleStatusCustom2 (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        remove (item) {
            this.toggleStatusCustom(seedFertilizerServiceBaseUrl, portAllocationDestroy, item)
        },
        finalSave (item) {
          this.toggleStatusCustom2(seedFertilizerServiceBaseUrl, portAllocationFinalSave, item)
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, portAllocationList, params).then(response => {
                if (response.success) {
                    // this.$store.dispatch('setList', response.data.data)
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        async getLcList() {
          const result = await RestApi.getData(seedFertilizerServiceBaseUrl, lcList)
          if (result.success) {
            this.lcList = result.data
          } else {
            this.lcList = []
          }
        },
        getCustomDataList(data) {
          const listData = data.map(item => {
            const lcObj = this.lcList.find(lcItem => lcItem.lc_no === item.lc_no)
            const lcData = {}
            if(typeof lcObj !== 'undefined') {
              lcData.ship_name = lcObj.ship_name
              lcData.ship_name_bn = lcObj.ship_name_bn
            } else {
              lcData.ship_name = ''
              lcData.ship_name_bn = ''
            }
            const fertilizerObj = this.fertilizerNameList.find(fertilizerItem => fertilizerItem.value === item.fertilizer_id)
            const fertilizerData = {}
            if(typeof fertilizerObj !== 'undefined') {
              fertilizerData.fertilizer_name = fertilizerObj.text_en
              fertilizerData.fertilizer_name_bn = fertilizerObj.text_bn
            } else {
              fertilizerData.fertilizer_name = ''
              fertilizerData.fertilizer_name_bn = ''
            }
            const countryObj = this.importCountryList.find(item => item.value === lcObj.country_id)
            const countryData = {}
            if(typeof countryObj !== 'undefined') {
              countryData.country_name = countryObj.text_en
              countryData.country_name_bn = countryObj.text_bn
            } else {
              countryData.country_name = ''
              countryData.country_name_bn = ''
            }
            return Object.assign({}, item, lcData, fertilizerData, countryData)
          })
          return listData
        },
        pdfExport () {
          this.$refs.details.pdfExport()
        }
    }
}
</script>

<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
