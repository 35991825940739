<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
            <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                  {{ $t('movement.portWiseAllocationManagement') }}
                </list-report-head>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" sm="6">
                <table class="table table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td>{{ $t('globalTrans.date') }}</td>
                      <td>{{ getFormattedDate(formData.allocation_date) }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('procurement.lc_no') }}</td>
                      <td>
                        {{$n(formData.lc_no, { useGrouping: false })}}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('movement.sourceCountry') }}</td>
                      <td>{{ currentLocale === 'en' ? formData.country_name : formData.country_name_bn }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('movement.billAmount') }}</td>
                      <td>{{ $n(lcInfo.quantity) }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('procurement.loading_date') }}</td>
                      <td>{{ lcInfo.loading_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('movement.actual_arival_date') }}</td>
                      <td>{{ formData.actual_arival_date | dateFormat }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col lg="6" sm="6">
                <table class="table table-bordered table-striped">
                  <tbody>
                  <tr>
                    <td>{{ $t('procurement.memo_no') }}</td>
                    <td>{{ formData.memo_no }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('movement.vesselsName') }}</td>
                    <td>{{ currentLocale === 'en' ? formData.ship_name : formData.ship_name_bn }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('movement.fertilizerName') }}</td>
                    <td>{{ currentLocale === 'en' ? formData.fertilizer_name : formData.fertilizer_name_bn }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('movement.actual_receipt_amount') }}</td>
                    <td>{{ $n(formData.actual_receipt_amount) }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('procurement.estimated_arival_date') }}</td>
                    <td>{{ lcInfo.estimated_arival_date | dateFormat}}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('movement.currentAvailableAmount') }}</td>
                    <td>{{ $n(currentAvailableAmount) }}</td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-table-simple class="mt-3" hover bordered small caption-top responsive>
              <b-thead head-variant="secondary">
                <b-tr>
                  <b-td class="text-center">{{ $t('globalTrans.sl_no') }}</b-td>
                  <b-td>{{ $t('movement.nameOfPorts') }}</b-td>
                  <b-td class="text-right">{{ $t('movement.allocatedAmount') }}</b-td>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(portInfoItem, index) in formData.port_info" :key="index">
                  <b-td class="text-center">{{ $n(index+1) }}</b-td>
                  <b-td>{{ currentLocale === 'en' ? portInfoItem.port_name : portInfoItem.port_name_bn }}</b-td>
                  <b-td class="text-right">
                    {{ $n(portInfoItem.allocated_amount) }}
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
                <b-tr variant="" >
                  <b-th colspan="2" class="text-right">
                    {{ $t('sitePreference.total') }}:
                  </b-th>
                  <th class="text-right">{{ $n(formData.total_allocated_amount) }}</th>
                </b-tr>
                <b-tr variant="">
                  <b-th colspan="2" class="text-right">
                    {{ $t('movement.currentAvailableAmount') }}:
                  </b-th>
                  <th class="text-right">{{ $n(currentAvailableAmount) }}</th>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { lcList, lcInfo, portAllocationShow } from '@/modules/seeds-fertilizer-service/fertilizer/movement/api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.id) {
      const tmp = this.getPortAllocationData()
      this.formData = tmp
      this.formData.port_info = []
      const portDetailsInfo = tmp.port_allocation_ports
      this.portList.filter(item => {
        const portDetailsItem = portDetailsInfo.find(portDetailsObj => portDetailsObj.port_id === item.value)
        const portInfoItem = {
          port_id: item.value,
          allocated_amount: typeof portDetailsItem !== 'undefined' ? portDetailsItem.allocated_amount : '',
          port_name: item.text_en,
          port_name_bn: item.text_bn
        }
        this.formData.port_info.push(portInfoItem)
      })
    }
  },
  mounted () {
    core.index()
    this.getLcInfo()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      formData: {
        id: '',
        allocation_date: '',
        memo_no: '',
        lot_no: '',
        lc_no: '',
        ship_name: '',
        ship_name_bn: '',
        country_name: '',
        country_name_bn: '',
        fertilizer_id: '',
        fertilizer_name: '',
        bill_amount: '',
        total_allocated_amount: '',
        loading_date: '',
        estimated_arival_date: '',
        actual_arival_date: '',
        port_info: []
      },
      totalAvailableAmount: 0,
      currentAvailableAmount: 0,
      lcList: [],
      lcInfo: {},
      portDetailsInfo: [],
      loading: false
    }
  },
  computed: {
    portList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    importCountryList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getFormattedDate (date) {
      const dateArr = date.split('-')
      return this.$n(parseInt(dateArr[0]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[1]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[2]), { useGrouping: false })
    },
    getPortAllocationData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async getPortDetailsInfo () {
      this.loading = true
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, portAllocationShow + this.id)
      let portDetailsInfo = []
      if (result.success) {
        portDetailsInfo = result.data.port_allocation_ports
        this.portList.filter(item => {
          const portDetailsItem = portDetailsInfo.find(portDetailsObj => portDetailsObj.port_id === item.value)
          const portInfoItem = {
            port_id: item.value,
            allocated_amount: typeof portDetailsItem !== 'undefined' ? portDetailsItem.allocated_amount : '',
            port_name: item.text_en,
            port_name_bn: item.text_bn
          }
          this.formData.port_info.push(portInfoItem)
        })
      } else {
        portDetailsInfo = []
      }
      return portDetailsInfo
    },
    async getLcList () {
      this.loading = true
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, lcList)
      if (result.success) {
        this.lcList = result.data
      } else {
        this.lcList = []
      }
      this.loading = false
    },
    async getLcInfo () {
      this.loading = true
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, lcInfo + this.formData.lc_no)
      if (!result.success) {
        this.lcErrorMsg = result.message
        this.formData.ship_name = ''
        this.formData.ship_name_bn = ''
        this.formData.country_name = ''
        this.formData.country_name_bn = ''
        this.formData.fertilizer_id = ''
        this.formData.fertilizer_name = ''
        this.formData.fertilizer_name_bn = ''
        this.formData.bill_amount = ''
        this.formData.total_allocated_amount = ''
      } else {
        this.lcErrorMsg = ''
        const lcInfo = result.data
        this.lcInfo = result.data
        if (typeof lcInfo !== 'undefined') {
          const countryObj = this.importCountryList.find(item => item.value === lcInfo.country_id)
          const fartilizerObj = this.fertilizerNameList.find(item => item.value === lcInfo.fertilizer_id)
          this.formData.ship_name = lcInfo.ship_name
          this.formData.ship_name_bn = lcInfo.ship_name_bn
          this.formData.country_name = typeof countryObj !== 'undefined' ? countryObj.text_en : ''
          this.formData.country_name_bn = typeof countryObj !== 'undefined' ? countryObj.text_bn : ''
          this.formData.fertilizer_id = lcInfo.fertilizer_id
          this.formData.fertilizer_name = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_en : ''
          this.formData.fertilizer_name_bn = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_bn : ''
          this.formData.bill_amount = parseFloat(lcInfo.quantity)
          // this.formData.total_allocated_amount = parseFloat(lcInfo.quantity)
        } else {
          this.formData.ship_name = ''
          this.formData.ship_name_bn = ''
          this.formData.country_name = ''
          this.formData.country_name_bn = ''
          this.formData.fertilizer_id = ''
          this.formData.fertilizer_name = ''
          this.formData.fertilizer_name_bn = ''
          this.formData.bill_amount = ''
          this.formData.total_allocated_amount = ''
        }
        this.currentAvailableAmount = parseFloat(this.formData.actual_receipt_amount) - parseFloat(this.formData.total_allocated_amount)
        this.loading = false
      }
    },
    // pdfExport () {
    //   const rowData = this.getPdfData()
    //   const header = [
    //     [{ text: this.$t('globalTrans.date') }, { text: this.getFormattedDate(this.formData.allocation_date) }, { text: this.$t('procurement.memo_no') }, { text: this.formData.memo_no }],
    //     [{ text: this.$t('procurement.lc_no') }, { text: this.formData.lc_no }, { text: this.$t('movement.vesselsName') }, { text: this.currentLocale === 'en' ? this.formData.ship_name : this.formData.ship_name_bn }],
    //     [{ text: this.$t('movement.sourceCountry') }, { text: this.currentLocale === 'en' ? this.formData.country_name : this.formData.country_name_bn }, { text: this.$t('movement.fertilizerName') }, { text: this.currentLocale === 'en' ? this.formData.fertilizer_name : this.formData.fertilizer_name_bn }],
    //     [{ text: this.$t('movement.billAmount') }, { text: this.$n(this.lcInfo.quantity) }, { text: this.$t('movement.currentAvailableAmount') }, { text: this.$n(this.currentAvailableAmount) }]
    //   ]
    //   const labels = [
    //     { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), margin: [0, 50, 0, 0] },
    //     { text: this.$t('movement.nameOfPorts'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), margin: [0, 50, 0, 0] },
    //     { text: this.$t('movement.allocatedAmount'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), margin: [0, 50, 0, 0] },
    //     { text: '', margin: [0, 50, 0, 0] }
    //   ]
    //   const footer = [
    //     [
    //       { text: '' }, { text: this.$t('sitePreference.total') }, { text: this.$n(this.formData.total_allocated_amount) }, { text: '' }
    //     ],
    //     [
    //       { text: '' }, { text: this.$t('movement.currentAvailableAmount') }, { text: this.$n(this.currentAvailableAmount) }, { text: '' }
    //     ]
    //   ]
    //   rowData.unshift(labels)
    //   const finalData = header.concat(rowData, footer)
    //   const reportTitle = this.$i18n.locale === 'en' ? 'Port Wise Allocation Management' : 'বন্দর অনুযায়ী বরাদ্দ ব্যবস্থাপনা'
    //   const columnWids = ['10%', '40%', '30%', '20%']
    //   ExportPdf.exportPdf(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, finalData, columnWids)
    // },
    // getPdfData () {
    //   const keys = [
    //     { key: 'serial_no' },
    //     { key: this.$i18n.locale === 'en' ? 'port_name' : 'port_name_bn' },
    //     { key: 'allocated_amount' },
    //     { key: 'blank' }
    //   ]
    //   var serial = 0
    //   const listData = this.formData.port_info.map(item => {
    //     serial += 1
    //     const rowData = keys.map((keyItem, index) => {
    //       if (keyItem.key === 'serial_no') {
    //         return { text: this.$n(serial) }
    //       }
    //       if (keyItem.key === 'allocated_amount') {
    //         return { text: this.$n(item[keyItem.key]) }
    //       }
    //       if (keyItem.key === 'blank') {
    //         return { text: '' }
    //       }

    //       return { text: item[keyItem.key] }
    //     })
    //     return rowData
    //   })

    //   return listData
    // },
    pdfExport () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Port Wise Allocation Management' : 'বন্দর অনুযায়ী বরাদ্দের ব্যবস্থাপনা'
        ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this, this.lcInfo, this.currentAvailableAmount)
    }
  }
}
</script>
