import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, lcInfo, currentAvailableAmount) => {
    try {
        Store.commit('mutateCommonProperties', {
            loading: true
        })
        if (i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
                columns: reportHeadData.reportHeadColumn
            },
            {
                columns: [
                    { width: '*', text: '' },
                    { width: '*', text: '' },
                    { width: '*', text: vm.$t('fertilizerReport.krishi_bhaban') },
                    { width: '*', text: '' },
                    { width: '*', text: '' }
                ],
                style: 'krishi'
            },
            { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
            pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['*', '*', '*', '*'],
                body: [
                    [
                        { text: vm.$t('globalTrans.date'), style: 'td' },
                        { text: dateFormat(data.allocation_date), style: 'td' },
                        { text: vm.$t('procurement.memo_no'), style: 'td' },
                        { text: data.memo_no, style: 'td' }
                    ],
                    [
                        { text: vm.$t('procurement.lc_no'), style: 'td' },
                        { text: vm.$n(data.lc_no), style: 'td' },
                        { text: vm.$t('movement.vesselsName'), style: 'td' },
                        { text: (i18n.locale === 'en') ? data.ship_name : data.ship_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('movement.sourceCountry'), style: 'td' },
                        { text: vm.$i18n.locale === 'en' ? data.country_name : data.country_name_bn, style: 'td' },
                        { text: vm.$t('movement.fertilizerName'), style: 'td' },
                        { text: (i18n.locale === 'en') ? data.fertilizer_name : data.fertilizer_name_bn, style: 'td' }
                    ],
                    [
                        { text: vm.$t('movement.billAmount'), style: 'td' },
                        { text: vm.$n(lcInfo.quantity), style: 'td' },
                        { text: vm.$t('movement.actual_receipt_amount'), style: 'td' },
                        { text: vm.$n(data.actual_receipt_amount), style: 'td' }
                    ],
                    [
                        { text: vm.$t('procurement.loading_date'), style: 'td' },
                        { text: dateFormat(lcInfo.loading_date), style: 'td' },
                        { text: vm.$t('procurement.estimated_arival_date'), style: 'td' },
                        { text: dateFormat(lcInfo.estimated_arival_date), style: 'td' }
                    ],
                    [
                        { text: vm.$t('movement.actual_arival_date'), style: 'td' },
                        { text: dateFormat(data.actual_arival_date), style: 'td' },
                        { text: vm.$t('movement.currentAvailableAmount'), style: 'td' },
                        { text: vm.$n(currentAvailableAmount), style: 'td' }
                    ]
                ]
            }
        })
        pdfContent.push({ text: '', style: 'transport', alignment: 'center' })
        var allRow = []
        var tabledata = [
            { text: vm.$t('globalTrans.sl_no'), style: 'td', alignment: 'center' },
            { text: vm.$t('movement.nameOfPorts'), style: 'td' },
            { text: vm.$t('movement.allocatedAmount'), style: 'td', alignment: 'right' }
        ]
        allRow.push(tabledata)
        var serial = 0
        data.port_info.map(item => {
            serial += 1
            tabledata = [
                { text: vm.$n(serial), style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? item.port_name_bn : item.port_name, style: 'td' },
                { text: vm.$n(item.allocated_amount), style: 'td', alignment: 'right' }
            ]
            allRow.push(tabledata)
        })
        tabledata = [
            { text: vm.$t('sitePreference.total'), colSpan: '2', style: 'th', alignment: 'right' },
            {},
            { text: vm.$n(data.total_allocated_amount), style: 'td', alignment: 'right' }
        ]
        allRow.push(tabledata)
        tabledata = [
            { text: vm.$t('movement.currentAvailableAmount'), colSpan: '2', style: 'th', alignment: 'right' },
            {},
            { text: vm.$n(currentAvailableAmount), style: 'td', alignment: 'right' }
        ]
        allRow.push(tabledata)
        pdfContent.push({
            table: {
                headerRows: 0,
                style: 'header2',
                widths: ['15%', '45%', '40%'],
                body: allRow
            }
        })
        var docDefinition = {
            content: pdfContent,
            pageSize: 'A4',
            pageOrientation: 'Portrait',
            watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
            styles: {
                th: {
                    fillColor: '',
                    fontSize: 10,
                    bold: true,
                    margin: [3, 3, 3, 3]
                },
                td: {
                    fontSize: 10,
                    margin: [3, 3, 3, 3]
                },
                header: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 0, 0, 4]
                },
                header2: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 10, 0, 20]
                },
                transport: {
                    fontSize: 10,
                    margin: [10, 10, 0, 20]
                },
                headerPort1: {
                    fontSize: 10,
                    margin: [0, 20, 0, 0]
                },
                headerPort: {
                    fontSize: 10,
                    margin: [0, 4, 0, 15]
                },
                header3: {
                    fontSize: 9,
                    margin: [0, 0, 0, 4]
                },
                tableSubHead: {
                    margin: [0, 5, 0, 15]
                },
                krishi: {
                    margin: [0, -15, 0, 15],
                    alignment: 'center'
                }
            }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download('port-allocation')
    } catch (error) {
        if (error) {}
    }
    Store.commit('mutateCommonProperties', {
        loading: false
    })
}
export default {
    exportPdfDetails
}
