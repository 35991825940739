<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12" class="form-for-textarea">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                      <b-row>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Allocation Date" vid="allocation_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="allocation_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.date')}} <span class="text-danger">*</span>
                                </template>
                                <flat-pickr class="form-control"
                                            v-model="formData.allocation_date"
                                            :placeholder="$t('globalTrans.select_date')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                ></flat-pickr>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Memo No"  vid="memo_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="memo_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('procurement.memo_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="memo_no"
                                    v-model="formData.memo_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Lot No"  vid="lot_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="lot_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('procurement.lot_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="lot_no"
                                    v-model="formData.lot_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="LC No"  vid="lc_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="lc_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('procurement.lc_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="lc_no"
                                    v-model="formData.lc_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    @change="getLcInfo"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                                <div class="invalid-feedback d-block" v-if="lcErrorMsg">
                                  {{ lcErrorMsg }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="vessels_name"
                            >
                              <template v-slot:label>
                                {{$t('movement.vessels_name')}}
                              </template>
                              <b-form-input
                                  id="vessels_name"
                                  v-model="formData.ship_name"
                                  v-if="currentLocale === 'en'"
                                  readonly
                              ></b-form-input>
                              <b-form-input
                                  id="vessels_name"
                                  v-model="formData.ship_name_bn"
                                  v-else
                                  readonly
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="country_name"
                            >
                              <template v-slot:label>
                                {{$t('movement.sourceCountry')}}
                              </template>
                              <b-form-input
                                  id="country_name"
                                  v-model="formData.country_name"
                                  v-if="currentLocale === 'en'"
                                  readonly
                              ></b-form-input>
                              <b-form-input
                                  id="country_name"
                                  v-model="formData.country_name_bn"
                                  v-else
                                  readonly
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Fertilizer Name"  vid="fertilizer_name" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fertilizer_name"
                              >
                                <template v-slot:label>
                                  {{$t('movement.fertilizerName')}}
                                </template>
                                <b-form-input
                                    id="fertilizer_name"
                                    v-model="formData.fertilizer_name"
                                    v-if="currentLocale === 'en'"
                                    readonly
                                ></b-form-input>
                                <b-form-input
                                    id="fertilizer_name"
                                    v-model="formData.fertilizer_name_bn"
                                    v-else
                                    readonly
                                ></b-form-input>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="bill_amount"
                            >
                              <template v-slot:label>
                                {{$t('movement.billAmount')}}
                              </template>
                              <b-form-input
                                  id="bill_amount"
                                  type="text"
                                  v-model="formData.bill_amount"
                                  readonly
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Fertilizer Name"  vid="current_available_amount" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="current_available_amount"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('movement.currentAvailableAmount')}}
                              </template>
                              <b-form-input
                                  id="current_available_amount"
                                  type="number"
                                  v-model="formData.current_available_amount"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  readonly
                              ></b-form-input>
                            </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Actual Receipt Amount"  vid="actual_receipt_amount" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="actual_receipt_amount"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('movement.actual_receipt_amount')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="actual_receipt_amount"
                                  type="number"
                                  v-model="formData.actual_receipt_amount"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="loading_date"
                            >
                              <template v-slot:label>
                                {{ $t('procurement.loading_date') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input class="form-control"
                                            v-model="formData.loading_date"
                                            :placeholder="$t('globalTrans.select_date')"
                                            id="datepicker"
                                            readonly
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="estimated_arival_date"
                            >
                              <template v-slot:label>
                                {{$t('procurement.estimated_arival_date')}} <span class="text-danger">*</span>
                              </template>
                              <flat-pickr class="form-control"
                                            v-model="formData.estimated_arival_date"
                                            :placeholder="$t('globalTrans.select_date')"
                                            id="datepicker"
                                            readonly
                              ></flat-pickr>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Actual Arival Date" vid="actual_arival_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="actual_arival_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.actual_arival_date')}} <span class="text-danger">*</span>
                                </template>
                                <flat-pickr class="form-control"
                                              v-model="formData.actual_arival_date"
                                              :placeholder="$t('globalTrans.select_date')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                ></flat-pickr>
                                <div class="invalid-feedback d-block" v-if="errors.length">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </b-row>
                      <b-table-simple class="mt-3" hover bordered small caption-top responsive>
                        <b-thead head-variant="secondary">
                          <b-tr>
                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th>{{ $t('movement.name_of_ports') }}</b-th>
                            <b-th>{{ $t('movement.allocated_amount') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="(portInfoItem, index) in formData.port_info" :key="index">
                            <b-th class="text-center">{{ $n(index+1) }}</b-th>
                            <b-th>{{ currentLocale === 'en' ? portInfoItem.port_name : portInfoItem.port_name_bn }}</b-th>
                            <b-th class="text-right">
                              <b-form-group
                              >
                                <b-form-input
                                    :id="'allocated_amount_'+(index+1)"
                                    :ref="'allocated_amount_'+(index+1)"
                                    v-model.number="portInfoItem.allocated_amount"
                                    @keyup="getTotalAmount"
                                    @keypress="isNumber($event)"
                                ></b-form-input>
                              </b-form-group>
                            </b-th>
                          </b-tr>
                        </b-tbody>
                        <b-tfoot>
                          <b-tr variant="secondary" >
                            <b-th colspan="2" class="text-right">
                              {{ $t('sitePreference.total') }}:
                            </b-th>
                            <th class="text-right">{{ $n(totalAllocatedAmount) }}</th>
                          </b-tr>
                          <b-tr variant="secondary">
                            <b-th colspan="2" class="text-right">
                              {{ $t('movement.availableAmount') }}:
                            </b-th>
                            <th class="text-right">{{ $n(totalAvailableAmount) }}</th>
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                      <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { portAllocationStore, portAllocationUpdate, portAllocationVerifyLcNumber, lcInfo } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.formData.bill_amount = ''
      this.formData.port_info = []
      this.getCurrentLcInfo()
      const portDetailsInfo = tmp.port_allocation_ports
      this.portList.filter(item => {
        const portDetailsItem = portDetailsInfo.find(portDetailsObj => portDetailsObj.port_id === item.value)
        const portInfoItem = {
          port_allocation_port_id: portDetailsItem.id,
          port_id: item.value,
          allocated_amount: typeof portDetailsItem !== 'undefined' ? portDetailsItem.allocated_amount : '',
          port_name: item.text_en,
          port_name_bn: item.text_bn
        }
        this.formData.port_info.push(portInfoItem)
      })
    } else {
      this.portList.filter(item => {
        const portInfoItem = {
          port_id: item.value,
          allocated_amount: '',
          port_name: item.text_en,
          port_name_bn: item.text_bn
        }
        this.formData.port_info.push(portInfoItem)
      })
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      loading: false,
      formData: {
        id: '',
        allocation_date: '',
        memo_no: '',
        lot_no: '',
        lc_no: '',
        ship_name: '',
        ship_name_bn: '',
        country_name: '',
        country_name_bn: '',
        fertilizer_id: '',
        fertilizer_name: '',
        bill_amount: '',
        total_allocated_amount: '',
        current_available_amount: '',
        actual_receipt_amount: '',
        loading_date: '',
        estimated_arival_date: '',
        actual_arival_date: '',
        port_info: []
      },
      totalAllocatedAmount: 0,
      totalAvailableAmount: 0,
      lcErrorMsg: ''
    }
  },
  computed: {
    portList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    importCountryList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    // 'formData.lc_no': function (newVal, oldVal) {
    //   this.getLcInfo()
    // }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${portAllocationUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, portAllocationStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
        this.loading = false
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.success({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
        this.loading = false
      }
    },
    async getLcInfo () {
      this.loading = true
      const result = await RestApi.postData(seedFertilizerServiceBaseUrl, portAllocationVerifyLcNumber, this.formData)
      if (!result.success) {
        this.lcErrorMsg = result.message
        this.formData.ship_name = ''
        this.formData.ship_name_bn = ''
        this.formData.country_name = ''
        this.formData.country_name_bn = ''
        this.formData.fertilizer_id = ''
        this.formData.fertilizer_name = ''
        this.formData.fertilizer_name_bn = ''
        this.formData.bill_amount = ''
        this.formData.current_available_amount = ''
        this.formData.actual_receipt_amount = ''
        this.formData.loading_date = ''
        this.formData.estimated_arival_date = ''
      } else {
        this.lcErrorMsg = ''
        const lcInfo = result.data
        if (typeof lcInfo !== 'undefined') {
          const countryObj = this.importCountryList.find(item => item.value === lcInfo.country_id)
          const fartilizerObj = this.fertilizerNameList.find(item => item.value === lcInfo.fertilizer_id)
          this.formData.ship_name = lcInfo.ship_name
          this.formData.ship_name_bn = lcInfo.ship_name_bn
          this.formData.country_name = typeof countryObj !== 'undefined' ? countryObj.text_en : ''
          this.formData.country_name_bn = typeof countryObj !== 'undefined' ? countryObj.text_bn : ''
          this.formData.fertilizer_id = lcInfo.fertilizer_id
          this.formData.fertilizer_name = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_en : ''
          this.formData.fertilizer_name_bn = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_bn : ''
          this.formData.bill_amount = parseFloat(lcInfo.quantity)
          this.formData.total_allocated_amount = parseFloat(lcInfo.quantity)
          this.formData.current_available_amount = parseFloat(lcInfo.quantity)
          this.formData.actual_receipt_amount = parseFloat(lcInfo.quantity)
          this.formData.loading_date = lcInfo.loading_date
          this.formData.estimated_arival_date = lcInfo.estimated_arival_date
        } else {
          this.formData.ship_name = ''
          this.formData.ship_name_bn = ''
          this.formData.country_name = ''
          this.formData.country_name_bn = ''
          this.formData.fertilizer_id = ''
          this.formData.fertilizer_name = ''
          this.formData.fertilizer_name_bn = ''
          this.formData.bill_amount = ''
          this.formData.total_allocated_amount = ''
          this.formData.current_available_amount = ''
          this.formData.actual_receipt_amount = ''
          this.formData.loading_date = ''
          this.formData.estimated_arival_date = ''
        }
      }
      this.loading = false
    },
    async getCurrentLcInfo () {
      this.loading = true
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, lcInfo + this.formData.lc_no)
      if (!result.success) {
        this.lcErrorMsg = result.message
        this.formData.ship_name = ''
        this.formData.ship_name_bn = ''
        this.formData.country_name = ''
        this.formData.country_name_bn = ''
        this.formData.fertilizer_id = ''
        this.formData.fertilizer_name = ''
        this.formData.fertilizer_name_bn = ''
        this.formData.bill_amount = ''
        this.formData.total_allocated_amount = ''
        this.formData.current_available_amount = ''
        this.formData.loading_date = ''
        this.formData.estimated_arival_date = ''
      } else {
        this.lcErrorMsg = ''
        const lcInfo = result.data
        this.lcInfo = result.data
        if (typeof lcInfo !== 'undefined') {
          const countryObj = this.importCountryList.find(item => item.value === lcInfo.country_id)
          const fartilizerObj = this.fertilizerNameList.find(item => item.value === lcInfo.fertilizer_id)
          this.formData.ship_name = lcInfo.ship_name
          this.formData.ship_name_bn = lcInfo.ship_name_bn
          this.formData.country_name = typeof countryObj !== 'undefined' ? countryObj.text_en : ''
          this.formData.country_name_bn = typeof countryObj !== 'undefined' ? countryObj.text_bn : ''
          this.formData.fertilizer_id = lcInfo.fertilizer_id
          this.formData.fertilizer_name = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_en : ''
          this.formData.fertilizer_name_bn = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_bn : ''
          this.formData.bill_amount = lcInfo.quantity
          this.formData.current_available_amount = parseFloat(this.formData.actual_receipt_amount) - parseFloat(this.formData.total_allocated_amount)
          this.totalAllocatedAmount = this.formData.total_allocated_amount
          this.totalAvailableAmount = this.formData.actual_receipt_amount - this.formData.total_allocated_amount
          this.formData.loading_date = lcInfo.loading_date
          this.formData.estimated_arival_date = lcInfo.estimated_arival_date
        } else {
          this.formData.ship_name = ''
          this.formData.ship_name_bn = ''
          this.formData.country_name = ''
          this.formData.country_name_bn = ''
          this.formData.fertilizer_id = ''
          this.formData.fertilizer_name = ''
          this.formData.fertilizer_name_bn = ''
          this.formData.bill_amount = ''
          this.formData.total_allocated_amount = ''
          this.formData.current_available_amount = ''
        }
        // this.currentAvailableAmount = parseFloat(this.lcInfo.quantity) - parseFloat(this.formData.total_allocated_amount)
      }
      this.loading = false
    },
    getTotalAmount (event) {
      this.$nextTick(() => {
      let totalAllocatedAmount = 0
      this.formData.port_info.forEach((item, index) => {
        const id = '#allocated_amount_' + (index + 1)
        const currentId = event.target.id
        const currentIndex = parseInt(currentId.replace('allocated_amount_', '')) - 1
        if (currentIndex === index) {
          if (!parseFloat(item.allocated_amount)) {
            item.allocated_amount = 0
          }
          if (!parseFloat(this.formData.actual_receipt_amount)) {
            item.allocated_amount = 0
          }
        }
        totalAllocatedAmount += item.allocated_amount ? parseFloat(item.allocated_amount) : 0

        if (parseFloat(totalAllocatedAmount) > parseFloat(this.formData.actual_receipt_amount)) {
          const idValAmount = document.querySelector(id).value
          const remainingIdVal = Math.floor(idValAmount / 10)
          document.querySelector(id).value = remainingIdVal
          const newAllocatedAmount = remainingIdVal
          totalAllocatedAmount -= idValAmount
          totalAllocatedAmount += newAllocatedAmount
        }
      })
      // if (parseFloat(totalAllocatedAmount) > parseFloat(this.formData.bill_amount)) {
      //   this.$forceUpdate()
      // }
      this.totalAllocatedAmount = totalAllocatedAmount
      this.totalAvailableAmount = this.formData.actual_receipt_amount - totalAllocatedAmount
      })
    },
    isNumber (evt) {
      if (!evt) {
        evt = window.event
      }
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>
